<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:readOnly="readOnly"
			:showDeleteBtn="false"
			:modalDialog="modalDialog"
			:externalFilter="externalFilter"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<portcallclearancesstatus-modals :modelName="modelName"></portcallclearancesstatus-modals>
	</div>
</template>

<script>
import portcallclearancesstatusActions from './PortcallclearancesstatusActions';
import portcallclearancesstatusModals from './PortcallclearancesstatusModals';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'portcallclearancesstatus-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'portcallclearancesstatus-modals': portcallclearancesstatusModals
	},
	data() {
		return {
			modelName: 'portcallclearancesstatus',
			actions: portcallclearancesstatusActions.actions,
			modelColumnDefs: {
				arrimmigrationstatusdesc: this.generateCellStyle(),
				arrcustomsstatusdesc: this.generateCellStyle(),
				arrharbourstatusdesc: this.generateCellStyle(),
				depcustomsstatusdesc: this.generateCellStyle(),
				depharbourstatusdesc: this.generateCellStyle(),
				depimmigrationstatusdesc: this.generateCellStyle()
			}
		};
	},
	methods: {
		generateCellStyle() {
			return {
				render: (data) => {
					let style = '';
					switch (data) {
						case 'Documentation ready for review':
						case 'Documentation validated':
						case 'Check requested':
						case 'Check confirmed':
						case 'დოკუმენტაცია მზად არის განსახილველად':
						case 'დოკუმენტაცია დადასტურებულია':
						case 'შემოწმება მოთხოვნილია':
						case 'შემოწმება დადასტურებულია':
							style = 'background-color: #e9db1aa1;'; // Fondo amarillo
							break;
						case 'Cleared':
						case 'ნებართვა გაცემულია':
							style = 'background-color: #24a54e9e;'; // Fondo verde
							break;
						case 'Documentation rejected':
						case 'დოკუმენტაცია უარყოფილია':
							style = 'background-color: #e8624d;'; // Fondo rojo
							break;
						case 'Documentation pending':
						case 'დოკუმენტაცია მოლოდინის რეჟიმშია':
							style = 'background-color: #e9711aa1;'; // Fondo anaranjado
							break;
						case 'Rejected':
						case 'ნებართვა უარყოფილია':
							style = 'background-color: #e8624d;'; // Fondo rojo
							break;
						default:
							break;
					}

					return `<div style="${style} font-weight: bold; padding: 10px; border-radius: 10px">${data}</div>`;
				}
			};
		}
		/*filter() {
			let userCodes = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST;
			let cargoAgentIds = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS;
			let filter = null;
			let groups = [];

			if (userCodes.includes('CAAG') && !userCodes.includes('SHAG')) {
				let cargoAgentId = `#${cargoAgentIds}#`;
				groups.push({
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'cargoagentid', op: 'cn', data: cargoAgentId }]
				});
				groups.push({
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'cargoagentid', op: 'ne', data: '' }]
				});
				filter = { groupOp: 'and', rules: [], groups: groups };
			}

			return filter;
		}*/
	},
	mounted() {}
};
</script>
